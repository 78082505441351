import React from 'react';
import ymkinsurancecarriers from '../images/ymk-insurance-carriers.webp'

const RedFlags = () => {
    return (
        <>
            {/* Beginning of Businesses Header Section */}
            <div className="bg-black px-6 py-1 sm:py-10 sm:px-8">
                <div className="mx-auto max-w-4xl sm:max-w-4xl text-center">
                    <p className="text-lg font-semibold leading-7 text-white sm:text-xl">INSURANCE CARRIERS</p>
                    <h2 className="mt-5 text-4xl font-bold tracking-tight text-yellow-400 sm:text-4xl sm:mt-5">Cyber Breach Resilience: Partner with YMK-7</h2>
                    <p className="mx-auto mt-6 text-base leading-7 text-white text-center sm:text-center">It's every insurer's nightmare - a cyber breach hits, claims pile up, payouts escalate. Don't let this be your reality. Partner with us. Our expert incident response team works tirelessly to contain the breach, get your client back online, and curb potential claims.</p>
                </div>
            </div>
            {/* End of Businesses Header Section */}

            {/* Beginning of Image Header Section */}
            <div className="bg-black ">
                <div className="mx-auto px-6 py-16 sm:px-8 sm:ps-10 sm:max-w-7xl sm:py-5">
                    <div className="flex items-center">
                        <img src={ymkinsurancecarriers} alt="App screenshot" width="2432" height="1442" className="-mt-10 sm:-mt-10 rounded-md sm:pb-5 pb-1" />
                    </div>
                </div>
            </div>
            {/* End of Image Header Section */}

            {/* Beginning of Challenges & Solutions Sections */}
            <div className="isolate overflow-hidden bg-black">
                <div className="mx-auto sm:max-w-full pb-96 text-center sm:pt-6 sm:px-8">
                    <div className="mx-auto max-w-4xl">
                        <p className="mt-0 text-3xl font-bold tracking-tight text-white sm:text-5xl"> Challenges & Solutions <br className="hidden sm:hidden" /></p>
                    </div>
                </div>
                <div className="flow-root bg-black pb-16 sm:pb-10">
                    <div className="-mt-96 sm:-mt-80">
                        <div className="mx-auto max-w-screen-2xl px-6 sm:px-8">
                            <div className="mx-auto grid max-w-md grid-cols-1 gap-5 sm:max-w-4xl sm:grid-cols-2">
                                {/* Box #1 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">Prolonged Downtime Skyrocketing Claims</h3>
                                        <p className="mt-6 text-base leading-7 text-white">The more a client's business stays offline, the bigger their loss and the higher your payout.</p>
                                    </div>
                                </div>

                                {/* Box #2 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">We Cut Downtime.</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Our expert team works 24/7 to get your client's systems back up, minimizing claim costs.</p>
                                    </div>
                                </div>

                                {/* Box #3 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">How Do You Quantify Reputation Damage?</h3>
                                        <p className="mt-6 text-base leading-7 text-white">A client's tarnished reputation can lead to ongoing losses. How will you cover that?</p>
                                    </div>
                                </div>

                                {/* Box #4 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">Are You Ready for the Regulatory Heat?</h3>
                                        <p className="mt-6 text-base leading-7 text-white">After a breach, your clients face regulatory scrutiny. Their non-compliance can reflect on you.</p>
                                    </div>
                                </div>

                                {/* Box #3 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">Questions Over Cyber Insurance Claims?</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Filing an insurance claim post-breach is a nuanced game. A wrong move, and your client may face claim denials.</p>
                                    </div>
                                </div>

                                {/* Box #4 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">We Navigate Regulations."</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Our incident response team ensures your client meets post-breach regulatory requirements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Challenges & Solutions Sections */}
        </>
    );
}

export default RedFlags;
