import React from 'react'

const Stats = () => {
    return (
        <div className="isolate overflow-hidden bg-black">
            <div className="mx-auto max-w-7xl text-center sm:mt-5">
                <div className="mx-auto max-w-screen-4xl">
                    <p className="mt-16 text-3xl font-bold tracking-tight text-white sm:text-4xl sm:mt-0">24/7 Vigilance. Expertise Against Cyber threats.</p>
                </div>
                <div className="relative mt-10"></div>
            </div>
            <div className="flow-root bg-black pb-10 sm:pb-5">
                <div className="mt-3 sm:mt-5">
                    <div className="mx-auto grid max-w-md grid-cols-1 gap-4 sm:max-w-4xl sm:grid-cols-2">

                        {/* Box #1 */}
                        <div className="flex flex-col justify-between sm:rounded-3xl bg-black p-8 shadow-xl ring-4 ring-yellow-400 sm:p-10">
                            <div>
                                <h3 id="tier-incident-response" className="text-xl text-center font-bold leading-7 text-white">Revive Your Business</h3>
                                <p className="mt-2 text-center text-sm leading-7 text-white">Join the 1500+ Companies We've Helped Recover</p>
                            </div>
                        </div>

                        {/* Box #3 */}
                        <div className="flex flex-col justify-between sm:rounded-3xl bg-black p-8 shadow-xl ring-4 ring-yellow-400 sm:p-10">
                            <div>
                                <h3 id="tier-incident-response" className="text-xl text-center font-bold leading-7 text-white">Experience the Relief</h3>
                                <p className="mt-2 text-center text-sm leading-7 text-white">$ Billions Saved from Business Disruption Claims</p>
                            </div>
                        </div>

                        {/* Box #2 */}
                        <div className="flex flex-col justify-between sm:rounded-3xl bg-black p-8 shadow-xl ring-4 ring-yellow-400 sm:p-10">
                            <div>
                                <h3 id="tier-incident-response" className="text-xl text-center font-bold leading-7 text-white">Your Partner in Protection</h3>
                                <p className="mt-2 text-center text-sm leading-7 text-white">Solely Focused on Reducing Your Business Impact</p>
                            </div>
                        </div>

                        {/* Box #4 */}
                        <div className="flex flex-col justify-between sm:rounded-3xl bg-black p-8 shadow-xl ring-4 ring-yellow-400 sm:p-10">
                            <div>
                                <h3 id="tier-incident-response" className="text-xl text-center font-bold leading-7 text-white">Never Alone</h3>
                                <p className="mt-2 text-center text-sm leading-7 text-white">Experience 24/7 Support, Every Single Day</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats;
