import React from "react";
import NewsEvents from "../Sections/NewsEvents";
import Metrics from "../Sections/Metrics";
import Customers from "../Sections/Customers";
import TopBarMenu from "../Sections/TopBarMenu";
import Footer from "../Sections/Footer";

function News() {
  return (
    <div>
   	<TopBarMenu /> 
	  <NewsEvents />
    <Metrics />
    <Customers />
    <Footer />
    </div>
  );
}

export default News;