import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../Animations/trending.json';

const NewsEvents = () => {
    const container = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
        });
    }, []);

    return (
        <div className="bg-black py-1 sm:py-12">
            <div className="mx-auto max-w-7xl px-6 sm:px-8">
                <div className="mx-auto flex max-w-2xl flex-col items-start justify-between gap-16 sm:mx-0 sm:max-w-none sm:flex-row">
                    <div className="w-full sm:max-w-xl sm:flex-auto">
                        <h2 className="text-3xl font-bold tracking-tight text-yellow-400 sm:text-5xl text-center">News & Events</h2>
                        <div className="mt-5 sm:mt-16 aspect-[6/5] w-full rounded-2xl bg-gray-50 sm:aspect-auto sm:h-[34.5rem]" ref={container} ></div>
                    </div>
                    <div className="w-full sm:max-w-xl sm:flex-auto">
                        <ul className="-my-8 divide-y divide-gray-100">
                        <li className="py-8">
                                <dl className="relative flex flex-wrap gap-x-3">
                                    <dd className="w-full flex-none text-lg font-semibold tracking-tight text-yellow-400">
                                    SEC Adopts New Cybersecurity Disclosure Rules
                                    </dd>
                                    <dt className="sr-only">Description</dt>
                                    <dd className="mt-2 w-full flex-none text-base leading-7 text-white">New SEC Rules Require U.S. Companies to Reveal Cyber Attacks Within 4 Days.</dd>
                                </dl>
                            </li>
                            <li className="py-5">
                                <dl className="relative flex flex-wrap gap-x-3">
                                    <dd className="w-full flex-none text-lg font-semibold tracking-tight text-yellow-400">
                                    US ambassador to Beijing targeted in Chinese cyber-attack
                                    </dd>
                                    <dt className="sr-only">Description</dt>
                                    <dd className="mt-2 w-full flex-none text-base leading-7 text-white">The stealth and sophistication of the cyber-attack, exploited a flaw in Microsoft’s cloud computing environment.</dd>
                                </dl>
                            </li>
                            <li className="py-8">
                                <dl className="relative flex flex-wrap gap-x-3">
                                    <dd className="w-full flex-none text-lg font-semibold tracking-tight text-yellow-400">
                                    Johns Hopkins sued over May cybersecurity attack
                                    </dd>
                                    <dt className="sr-only">Description</dt>
                                    <dd className="mt-2 w-full flex-none text-base leading-7 text-white">Johns Hopkins University and Health System is being sued over a cybersecurity attack in May that affected their networks.</dd>
                                </dl>
                            </li>
                            <li className="py-8">
                                <dl className="relative flex flex-wrap gap-x-3">
                                    <dd className="w-full flex-none text-lg font-semibold tracking-tight text-yellow-400">
                                    Charter Oak bank back online after cyber breach
                                    </dd>
                                    <dt className="sr-only">Description</dt>
                                    <dd className="mt-2 w-full flex-none text-base leading-7 text-white">Charter Oak Federal Credit Union’s was back online Wednesday afternoon after several days of being down due to a cyber attack</dd>
                                </dl>
                            </li>
                            <li className="py-8">
                                <dl className="relative flex flex-wrap gap-x-3">
                                    <dd className="w-full flex-none text-lg font-semibold tracking-tight text-yellow-400">
                                    CISA Urges to Apply Patch for Windows and Office zero-days Immediately
                                    </dd>
                                    <dt className="sr-only">Description</dt>
                                    <dd className="mt-2 w-full flex-none text-base leading-7 text-white">An attacker could create a specially crafted Microsoft Office document that enables them to perform remote code execution in the context of the victim.</dd>
                                </dl>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsEvents;
