import React, { useState, useEffect } from 'react';

function IRProcess() {
  const [currentTab, setCurrentTab] = useState('Ransomware Response');
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (['ransomware-response', 'business-email-compromise', 'data-breach-response', 'cyber-extortion'].includes(tab)) {
      setCurrentTab(tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
    }
  }, []);

  return (
  <div>    
    {/* Desktop Layout */}
      <div className="hidden sm:block">
       <div className="sm:mx-auto max-w-screen-xl border-8 border-yellow-400">
        <nav className="-mb-px flex divide-x-8 mx-auto max-w-2xl divide-white space-x-4 sm:space-x-0 sm:max-w-7xl" aria-label="Tabs">
          <button onClick={() => setCurrentTab('Ransomware Response')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4 py-4 px-1 text-center text-sm font-bold tracking-tight sm:text-xl">Ransomware Response</button>
          <button  onClick={() => setCurrentTab('Business Email Compromise')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4  py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl">Business Email Compromise</button>
          <button  onClick={() => setCurrentTab('Data Breach Response')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4  py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl" aria-current="page">Data Breach Response</button>
          <button  onClick={() => setCurrentTab('Cyber Extortion')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4 py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl">Cyber Extortion</button>
        </nav>
        </div>
      </div>

    {/* Mobile Layout */}
    <div className="block sm:hidden">
      <div className="sm:mx-auto max-w-screen-xl border-8 border-yellow-400">
        <nav className="flex flex-wrap divide-y-4 mx-auto max-w-2xl divide-white sm:space-x-0 sm:max-w-7xl" aria-label="Tabs">
          <button  onClick={() => setCurrentTab('Ransomware Response')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-center text-lg font-bold -tracking-tighter sm:py-4">Ransomware Response</button>
          <button  onClick={() => setCurrentTab('Business Email Compromise')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold -tracking-tighter sm:py-4">Business Email Compromise</button>
          <button  onClick={() => setCurrentTab('Data Breach Response')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold -tracking-tighter sm:py-4" aria-current="page">Data Breach Response</button>
          <button  onClick={() => setCurrentTab('Cyber Extortion')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold -tracking-tightersm:py-4">Cyber Extortion</button>
        </nav>
      </div>
    </div>
    
    {/* Beginning of Ransomware Response */}
    {currentTab === 'Ransomware Response' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-24" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Ransomware Response</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - In the first moment of discovery, every second counts. Reach out to us immediately via phone or email. Your call for help sets our wheels in motion.</span>
                </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Initial Assessment</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Our expert team jumps into action, conducting a rapid, yet thorough analysis of your system to understand the depth and extent of the ransomware infection.</span>
                </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-xl sm:text-3xl font-semibold text-white" >Containment</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - To prevent further damage, we isolate affected systems, stop the spread of the ransomware, and secure your critical data. Our focus is on limiting impact and preserving your business operations.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Eradication & Recovery</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Here we get rid of the malicious code and start the recovery process. We work tirelessly to restore your systems and data back to their original state, minimizing downtime and disruption.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Post-Response Analysis</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - Learning from incidents is key to future prevention. We analyze the breach to understand how it happened, provide you a detailed report, and guide you on measures to prevent a repeat in the future.</span>
                </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
  )}

  {/* Beginning of Business Email Compromise Section */}
    {currentTab === 'Business Email Compromise' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-60 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-24" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Business Email Compromise</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 1 - At the first hint of email compromise, connect with us immediately. We'll spring into action the moment we receive your message or call.</span>
                </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-yellow-400 sm:h-16 sm:w-16 ">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Incident Triage</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Our team dives deep into your email systems to understand the scope of the breach. We assess compromised accounts, potential data loss, and the threat vector used for the attack.</span>
                </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-yellow-400 sm:h-16 sm:w-16">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Threat Isolation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Our next priority is to contain the breach. We secure your email system, isolating compromised accounts and blocking unauthorized access. Damage control is our focus.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Remediation & Recovery</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - We work to cleanse your email system, removing malware, phishing hooks, and any other threats. We help in recovering compromised data and restoring access to your email accounts.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Strengthening Security</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - Our work doesn't stop at recovery. We help you bolster your email security measures, educating you on best practices to prevent future compromises and enhance resilience.</span>
                </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
    )}
    
    {/* Beginning of Data Breach Response Section */}
    {currentTab === 'Data Breach Response' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black ">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-24" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Data Breach Response</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 1 - The moment you suspect a data breach, reach out to us. We're on standby, ready to leap into action the instant we receive your call or message.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-64 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Analysis & Identification</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Our experts immediately perform an initial assessment to identify the source, extent, and nature of the breach. We evaluate what data has been accessed or stolen, and how it happened.</span>
                </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-64 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Containment & Control</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - We focus on limiting the potential damage. This means containing the breach, securing compromised systems, and implementing immediate measures to prevent further data loss.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-64 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Eradication & Recovery</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Once contained, we work to eliminate the threat source, patch vulnerabilities, and recover any compromised data. Our aim is to return your operations to normalcy swiftly and securely.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Future-Proofing</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - We don't just fix the problem. We help you prevent future breaches by providing guidance on how to strengthen your data security measures and protocols. We enable you to build resilience.</span>
                </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
  )}
  
  {/* Beginning of Cyber Extortion Section */}
    {currentTab === 'Cyber Extortion' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black ">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-64 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-24" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Cyber Extortion</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 1 - Cyber extortion can bring operations to a standstill. The second you notice something amiss, reach out to us. We're prepared to swing into action 24/7.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Threat Analysis</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - We immediately launch into action by analyzing the nature, scope, and severity of the threat. Understanding the adversary and their method is key to devising a successful counter-strategy.</span>
                </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-56 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Containment</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Following the analysis, we move to contain the cyber threat, minimizing the potential impact on your systems. We work to mitigate any ongoing attacks and safeguard crucial assets.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Eradication & Recovery</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Next, we focus on eradicating the malicious components from your systems and initiating recovery processes. We aim to restore normalcy to your operations without succumbing to extortion demands.</span>
                </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Resilience Building</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - Once the immediate threat is neutralized, we help bolster your defenses. By analyzing the attack and your existing security posture, we provide guidance to fortify your resilience against future extortion attempts.</span>
                </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
  )}
</div>
);
}

export default IRProcess;
