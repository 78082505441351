import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../Animations/cyber-security-respond-1.json';
import { Link } from 'react-router-dom';

function Customers() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <div className="bg-black py-10 sm:py-1">
      <div className="relative isolate ">
        <div className="mx-auto sm:max-w-max sm:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 sm:p-8 sm:mx-0 sm:max-w-none sm:flex-row sm:items-center">
            <div ref={container} className="h-96 w-full flex-none object-cover aspect-square sm:h-auto sm:max-w-sm bg-black"></div>

            <div className="w-full flex-auto">
              <h2 className="-mt-20 text-4xl font-bold tracking-tight text-center sm:text-5xl sm:text-center text-yellow-400">Who We Serve</h2>
              <ul className="mt-10 ps-10 sm:mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-2xl sm:text-3xl leading-7 text-white sm:grid-cols-1 ">
                <li className="flex gap-x-3 sm:gap-x-5 ">
                  <svg className="h-8 w-7 flex-none fill-yellow-400 " viewBox="0 0 20 20 " >
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                  </svg>
                  <Link to="/business" className="cursor-pointer text-white font-bold hover:text-yellow-400">Small Businesses</Link>
                </li>
                <li className="flex gap-x-3 mt-2">
                  <svg className="h-8 w-7 flex-none fill-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                  </svg>
                  <Link to="/law-firms" className="cursor-pointer text-white font-bold hover:text-yellow-400">Law Firms</Link>
                </li>
                <li className="flex gap-x-3 mt-2 text-center sm:text-left">
                  <svg className="h-8 w-7 flex-none fill-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                  </svg>
                  <Link to="/insurance-carriers" className="cursor-pointer text-white font-bold hover:text-yellow-400">Insurance Carriers</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
