// GoogleTag.js
import React from 'react';
import TagManager from 'react-gtm-module';

const GoogleTag = () => {
  // Initialize Google Tag Manager
  TagManager.initialize({ gtmId: 'GTM-NZWK4TJT' }); 

  // Phone number conversion data
  const phoneConversionNumber = '+1 (844) 431-7615';

  // Push the phone conversion data to the dataLayer
  React.useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      phone_conversion_number: phoneConversionNumber,
    });
  }, []);

  return null; // This component doesn't render anything
};

export default GoogleTag;

  