import React from 'react';

const Metrics = () => {
  return (
    <div className="bg-black px-6 py-10 sm:py-10 sm:px-8">
      <div className="mx-auto max-w-4xl sm:max-w-6xl">
      <h3 className="text-4xl font-bold tracking-tight text-yellow-400 text-center sm:text-center sm:text-5xl">Grim Reality Of Cyber Breach</h3>
      <dl className="mt-5 sm:mt-10 grid grid-cols-1 divide-y divide-white overflow-hidden rounded-lg bg-black shadow sm:grid-cols-3 sm:divide-x-2 sm:divide-y-0 ring-4 ring-yellow-400">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-white">Businesses Supported</dt>
          <dd className="mt-1 flex items-baseline justify-between sm:flex">
            <div className="flex items-baseline text-2xl font-semibold text-white">
              2539 Cases
              <span className="ml-2 text-sm font-medium text-white">from 1450</span>
            </div>
            <div className="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-bold bg-yellow-400 text-black sm:mt-2">
              <svg className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-black" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clipRule="evenodd" />
              </svg>
              75.1%
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-white">$Millions Saved</dt>
          <dd className="mt-1 flex items-baseline justify-between sm:flex">
            <div className="flex items-baseline text-2xl font-semibold text-white">
              $356M
              <span className="ml-2 text-sm font-medium text-white">from 129</span>
            </div>
            <div className="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-bold bg-yellow-400 text-black">
              <svg className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-black" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clipRule="evenodd" />
              </svg>
              175.9%
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-white">Average Response Time</dt>
          <dd className="mt-1 flex items-baseline justify-between sm:flex">
            <div className="flex items-baseline text-2xl font-semibold text-white">
              3.5mins
              <span className="ml-2 text-sm font-medium text-white">from 9.7</span>
            </div>
            <div className="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-bold bg-yellow-400 text-black sm:mt-2 ">
              <svg className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-black" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clipRule="evenodd" />
              </svg>
              <span className="sr-only"> Decreased by </span>
              177.2%
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
  );
}

export default Metrics;
