import React from "react";
import Footer from "../Sections/Footer";
import RedFlags from "../Sections/RedFlags";
import Stats from "../Sections/Stats";
import TopBarMenu from "../Sections/TopBarMenu";
import ClicToAction from "../Sections/ClickToAction"




function InsuranceCarriers() {
  return (
    <div>
    <TopBarMenu />
    <RedFlags />
    <ClicToAction/> 
    <Stats /> 
    <Footer />
    </div>
  );
}

export default InsuranceCarriers;