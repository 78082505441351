import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../Animations/404-page-lottie.json';
import { Link } from 'react-router-dom';

function Error404() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-black sm:grid-cols-[max(50%,36rem),1fr]">
      <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-40 sm:col-span-2 sm:col-start-1 sm:row-start-1 sm:px-8">
      <Link to="/">
      </Link>
      </header>
      <main className="mx-auto w-full max-w-7xl px-6 py-44 sm:col-span-2 sm:col-start-1 sm:row-start-2 sm:px-8">
        <div className="max-w-lg sm:-mt-16">
          <p className="text-5xl sm:text-7xl font-semibold leading-8 text-white">404</p>
          <h1 className="mt-10 sm:mt-8 text-6xl sm:text-7xl font-bold tracking-tight text-yellow-400 ">Page not found</h1>
          <p className="mt-6 text-base sm:text-xl leading-7 text-white">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 sm:mt-20">
            <a href="/" className="text-sm sm:text-xl font-semibold leading-7 text-white"><span aria-hidden="true">&larr;</span> Back to home</a>
          </div>
        </div>
      </main>
      <footer className="self-end sm:col-span-2 sm:col-start-1 sm:row-start-3">
        <div className="border-t border-black bg-black py-10">
          <nav className="mx-auto flex w-full max-w-7xl items-center gap-x-4 px-6 text-sm leading-7 text-white sm:px-8">
          </nav>
        </div>
      </footer>
      <div className="hidden sm:relative sm:col-start-2 sm:row-start-1 sm:row-end-4 sm:block">
        <div ref={container} className="absolute inset-0 h-full w-full object-cover"></div>
      </div>
    </div>
  );
}

export default Error404;
