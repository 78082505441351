import React, { useEffect, Suspense } from 'react';

// Create an inline module for the ActualClickToAction component
const actualClickToActionModule = {
    default: function ActualClickToAction() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        useEffect(() => {
            const script = document.createElement('script');
            script.src = 'https://assets.calendly.com/assets/external/widget.js';
            script.async = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }, []);

  return (
    <div className="relative isolate overflow-hidden bg-black">
      <div className="mx-auto max-w-4xl px-6 sm:flex bg-black">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="mt-10 text-xl text-center font-bold tracking-tight text-white sm:text-3xl sm:mt-10">Immediate Breach Recovery Support</h2>
          <h2 className="mt-3 text-center text-2xl font-bold tracking-wider text-yellow-400 sm:text-5xl sm:mt-3">Get Peace of Mind Today!</h2>
          <h2 className="mt-1 text-center text-2xl font-bold tracking-wider text-yellow-400 sm:text-4xl sm:mt-3">Book Your CyberSecurity Expert Now</h2>
        </div>
      </div>
      <div className="mx-auto mt-10 sm:-mt-10 max-w-7xl items-center justify-center">
        {/* Calendly inline widget for mobile */}
        <div className="sm:hidden">
          <div className="calendly-inline-widget" data-url="https://calendly.com/ymk-7/25?hide_gdpr_banner=1&background_color=1a1a1a&text_color=ffd700&primary_color=ffe400" style={{ minWidth: '280px', height: '800px', minHeight: '800px' }}></div>
        </div>
        {/* Calendly inline widget for desktop */}
        <div className="hidden sm:flex sm:max-w-full mx-auto max-w-7xl items-center justify-center">
          <div className="calendly-inline-widget" data-url="https://calendly.com/ymk-7/25?hide_gdpr_banner=1&hide_gdpr_banner=1&background_color=1a1a1a&text_color=ffd700&primary_color=ffe400" style={{ minWidth: '90rem', height: '800px', minHeight: '800px' }}></div>
        </div>
        {/* End of Calendly inline widget */}
      </div>
    </div>
  );
  }
};

// Use React.lazy to lazily load the ActualClickToAction component
const ActualClickToActionLazy = React.lazy(() => {
  return new Promise(resolve => {
      setTimeout(() => {
          resolve(actualClickToActionModule);
      }, 500); // 1 second delay to simulate lazy loading, you can adjust this value
  });
});

const ClickToAction = () => {
const [loaded, setLoaded] = React.useState(false);

// Use the useEffect hook to set the loaded state to true 
// as soon as this component is mounted.
useEffect(() => {
    setLoaded(true);
}, []);

if (!loaded) return null;

return (
    <Suspense fallback={<div>Loading...</div>}>
        <ActualClickToActionLazy />
    </Suspense>
);
};

export default ClickToAction;
