import React from "react";
import Emergency from "../Sections/Emergency";
import FrequentlyAsked from "../Sections/FrequentlyAsked"
import Footer from "../Sections/Footer";
import TopBarMenu from "../Sections/TopBarMenu";
import Testimonials from "../Sections/Testimonials";
import ClickToAction from "../Sections/ClickToAction.js"


function Hotline() {
    return (
      <div>
      <TopBarMenu />   
      <Emergency /> 
      <Testimonials />
      <ClickToAction />
      <FrequentlyAsked />
      <Footer />
      </div>
    );
  }
  
  export default Hotline;