import React from "react";
import HeroSection from "../Sections/HeroSection.js";
import ClickToAction from "../Sections/ClickToAction.js"
import TopBarMenu from "../Sections/TopBarMenu.js";
import Solutions from "../Sections/Solutions.js";
import Customers from "../Sections/Customers.js";
import FrequentlyAsked from "../Sections/FrequentlyAsked.js";
import Footer from "../Sections/Footer.js";
import Burnaboy from "../Sections/Burnaboy.js";
import Testimonials from "../Sections/Testimonials.js";
import Metrics from "../Sections/Metrics";

function Homepage() {
  return (
    <>
    <TopBarMenu />
    <HeroSection />
    <Burnaboy />
    <Testimonials />
    <ClickToAction />
    <Metrics />
    <Customers />
    <Solutions />
    <FrequentlyAsked />
    <Footer />
    </>
  );
}

export default Homepage;