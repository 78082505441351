import React from "react";
import Footer from "../Sections/Footer";
import IRPageGraphic from "../Sections/IRPageGraphic";
import Stats from "../Sections/Stats";
import TopBarMenu from "../Sections/TopBarMenu";
import ClickToAction from "../Sections/ClickToAction"
import IRProcess from "../Sections/IRProcess"
import Testimonials from "../Sections/Testimonials"


function IncidentResponse() {
  return (
    <div>
    <TopBarMenu />
    <IRPageGraphic />     
    <IRProcess />
    <Testimonials /> 
    <ClickToAction /> 
    <Stats />
    <Footer />
    </div>
  );
}

export default IncidentResponse;