import React from 'react';

const Footer = () => {
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl px-6 py-12 pb:56 sm:flex flex-col sm:items-center sm:space-y-4">
        <p className="text-center text-lg leading-5 text-white sm:text-sm">&copy; 2023 YMK-7 LLC. All rights reserved.</p>
        <div className="flex justify-center">
          <button onClick={handleBackToTop} className="mt-5 sm:mt-1 text-center text-base leading-5 text-white sm:text-lg cursor-pointer">
            Back to Top
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;