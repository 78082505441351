import React from 'react';
import Slider from 'react-slick';
import headshot2 from '../images/headshot-2.webp'
import headshot3 from '../images/headshot-3.webp'
import headshot6 from '../images/headshot-6.webp'
import headshot8 from '../images/headshot-8.webp'
import headshot9 from '../images/headshot-9.webp'
import headshot10 from '../images/headshot-10.webp'

// Import slick carousel's css
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const testimonialsData = [
  {
    text: 'When we were hit with ransomware, I thought our company was done for. Within hours of contacting the team, they were working round the clock to recover our systems. Not only did we survive, but we are now stronger than ever."',
    image: headshot9,
    name: 'John Snow',
  },
  {
    text: 'We were not even aware of how exposed our systems were until the team conducted a logging visibility assessment. They found holes we did not know existed and helped us to plug them',
    image: headshot2,
    name: 'Mark Lance',
  },
  {
    text: 'Our cloud-based services faced a severe attack. Their swift response and expertise saved our operations from a total crash. They are now our go-to team for anything related to cyber security.',
    image: headshot3,
    name: 'Todd James',
  },
  {
    text: 'We got duped by a phishing scam that cost us thousands. The team worked relentlessly, tracing the email source and securing our systems. Today, we are smarter and safer, all thanks to them.',
    image: headshot10,
    name: 'Judith Black',
  },
  {
    text: 'We were struggling to understand how our security was breached. Their forensic investigations revealed the method and source of the attack. With their guidance, we have significantly strengthened our cyber defenses.',
    image: headshot8,
    name: 'Kevin Gabas',
  },
  {
    text: 'Our customer data got compromised. We contacted the team, fearing the worst. They swiftly contained the breach, restored our systems, and guided us to reassure our clients. Our business bounced back faster than we thought possible',
    image: headshot6,
    name: 'Josh Carpui',
  },
  // Add more testimonials as per your requirement
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 6000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 640, // For mobile devices with width less than 640px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-yellow-400 py-10 sm:py-10 lg:py-20">
      <div className="mx-auto -mt-5 max-w-2xl sm:max-w-5xl px-6 sm:px-8 lg:max-w-7xl">
      <h3 className="text-2xl font-bold mt-3 text-black text-center sm:text-center sm:text-4xl sm:mt-5 lg:text-5xl">Discover How Our Clients Achieved Success:</h3>
        <Slider {...settings}>
          {testimonialsData.map((testimonial, idx) => (
            <div className="flex flex-col pb-1 sm:pb-0 sm:pr-8" key={idx}>
              <img className="h-5 self-start" src={testimonial.logo} alt="" />
              <figure className="mt-1 sm:mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-base font-medium sm:text-lg sm:font-semibold text-black">
                  <p>{testimonial.text}</p>
                </blockquote>
                <figcaption className="mt-5 flex items-center gap-x-6">
                  <img className="h-14 w-14 rounded-full bg-black" src={testimonial.image} alt="" />
                  <div className="text-base">
                    <div className="font-semibold text-black">{testimonial.name}</div>
                    <div className="mt-1 text-black">{testimonial.position}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
