import React from "react";
import Magixx from "../Sections/Magixx";
import Footer from "../Sections/Footer";
import ClickToAction from "../Sections/ClickToAction";
import TopBarMenu from "../Sections/TopBarMenu";
import FrequentlyAsked from "../Sections/FrequentlyAsked.js";


function Business() {
  return (
    <div>
    <TopBarMenu />
    <Magixx />
    <ClickToAction />
    <FrequentlyAsked />
    <Footer />
    </div>
  );
}

export default Business;