import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../Animations/globe-map.json';
import ymkaboutus from '../images/ymk-about-us-1.webp'
import ymkaboutus12 from '../images/ymk-team-12.webp'
import ymkaboutus11 from '../images/ymk-team-11.webp'
import ymkaboutus9 from '../images/ymk-team-9.webp'

function Ruger() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    });
  }, []);

  return (
    <div className="bg-black">
      <div className="bg-black px-6 py-1 sm:py-16 sm:px-8">
        <div className="mx-auto max-w-4xl sm:max-w-full text-center">
          <h2 className="mt-5 text-4xl font-bold tracking-tight text-yellow-400 sm:text-5xl sm:mt-5 ">Meet the Heroes Behind Your Cyber Shield.</h2>
        </div>
      </div>

      <div className="mx-auto px-6 py-16 sm:px-8 sm:ps-10 sm:max-w-7xl sm:py-5">
        <div className="flex items-center"></div>
        <img src={ymkaboutus} alt="App screenshot" width="2432" height="1442" className="-mt-12 sm:-mt-10 rounded-md sm:pb-5 pb-1 " />
      </div>

      <div className="relative isolate overflow-hidden bg-black">
        <div className="px-6 py-1 sm:px-6 sm:py-2">
          <div className="mx-auto max-w-4xl text-center sm:text-center sm:max-w-4xl">
            <h2 className="text-2xl font-bold tracking-tight text-yellow-400 sm:text-4xl">The Story of Our Dedication.</h2>
            <h2 className="text-2xl font-bold tracking-tight text-yellow-400 sm:text-4xl sm:mt-1">Experience. Expertise. Excellence. That’s Us! </h2>
            <p className="mx-auto mt-6 text-lg leading-7 text-gray-300 text-center">
            Every minute matters when a cyber breach hits your business. Don't gamble with your business's future when every minute matters. Reach out to our expert team. Secure your future, now!
            </p>
          </div>
        </div>
      </div>

      <div className="overflow-hidden bg-black py-5">
        <div className="mx-auto max-w-4xl px-6 sm:flex sm:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 sm:mx-0 sm:min-w-full sm:gap-y-8 sm:col-end-1 sm:max-w-lg sm:pb-8 sm:mt-20">
            <div className="sm:col-end-1 max-w-4xl sm:max-w-sm sm:text-justify sm:-mt-10 ">
              <h2 className="mt-10 text-4xl font-bold tracking-tight text-center text-yellow-400 sm:text-white sm:text-5xl sm:text-center"> Our Story</h2>
              <p className="mt-3 text-lg leading-7 text-white text-center sm:text-lg">In the battleground of cyber threats, we're your frontline defense. Our team of seasoned cybersecurity experts brings a unique blend of skill, experience, and tenacity, to safeguard your business and bounce back even stronger from breaches</p>
              <p className="mt-5 text-lg leading-7 text-white text-center sm:text-lg">Our crew is an alliance of seasoned cybersecurity maestros. Drawing upon extensive knowledge and cutting-edge techniques, we stand between your business and a potential cyber catastrophe, ensuring your operations stay resilient and robust.</p>
            </div>
            <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 sm:contents">
              <div className="w-0 flex-auto sm:ml-auto sm:w-auto sm:flex-none sm:self-end">
                <img src={ymkaboutus11} alt="" className="aspect-[7/5] w-[25rem] sm:w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
              </div>
              <div className="contents sm:col-span-2 sm:col-end-2 sm:ml-auto sm:flex sm:w-[37rem] sm:items-start sm:justify-end sm:gap-x-8">
                <div className="flex w-64 flex-none justify-end self-end sm:w-auto"></div>
                <div className="flex w-96 flex-auto justify-end sm:w-auto sm:flex-none">
                  <img src={ymkaboutus12} alt="" className="aspect-[7/5] w-[30rem] sm:w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
                </div>
                <div className="order-last hidden sm:block sm:w-auto sm:flex-none">
                  <img src={ymkaboutus9} alt="" className="aspect-[4/3] w-[35rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black px-6 py-16 sm:py-7 sm:px-8">
        <div className="mx-auto max-w-2xl sm:max-w-full text-center">
          <h2 className="-mt-10 text-4xl font-bold tracking-tight text-center text-yellow-400 sm:text-yellow-400 sm:text-6xl sm:mt-5">Locations</h2>
          <p className="mx-auto mt-6 text-lg leading-7 text-white text-center sm:text-center">We have offices around the world with our global headquarters located in Chicago.</p>
        </div>
      </div>

      <div className="bg-black">
        <div className="flex justify-center sm:max-w-full px-6 sm:px-1 sm:py-1 sm:ps-10">
          <div className="flex items-center"></div>
          <div ref={container} className="-mt-10 pb-5 sm:pb-1 rounded-md"></div>
        </div>
      </div>
    </div>
  );
}

export default Ruger;
