import React, { useState, useEffect } from 'react';

function SOProcess() {
  const [currentTab, setCurrentTab] = useState('Cloud Incident Response');
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (['cloud-incident-response', 'logging-visibility-coverage', 'suspicious-email-analysis', 'security-awareness-training'].includes(tab)) {
      setCurrentTab(tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
    }
  }, []);

  return (
  <div>    
    {/* Desktop Layout */}
      <div className="hidden sm:block">
       <div className="sm:mx-auto max-w-screen-xl border-8 border-yellow-400">
        <nav className="-mb-px flex divide-x-8 mx-auto max-w-2xl  divide-white space-x-4 sm:space-x-0 sm:max-w-7xl" aria-label="Tabs">
          <button onClick={() => setCurrentTab('Cloud Incident Response')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4 py-4 px-1 text-center text-sm font-bold tracking-tight sm:text-xl">Cloud Incident Response</button>
          <button onClick={() => setCurrentTab('Logging Visibility Coverage')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4  py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl">Logging Visibility Coverage</button>
          <button onClick={() => setCurrentTab('Suspicious Email Analysis')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4  py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl" aria-current="page">Suspicious Email Analysis</button>
          <button onClick={() => setCurrentTab('Security Awareness Training')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4 py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl">Security Awareness Training</button>
        </nav>
        </div>
      </div>

    {/* Mobile Layout */}
    <div className="block sm:hidden">
      <div className="sm:mx-auto max-w-screen-xl border-8 border-yellow-400">
        <nav className="flex flex-wrap divide-y-4 mx-auto max-w-2xl divide-white sm:space-x-0 sm:max-w-7xl" aria-label="Tabs">
          <button onClick={() => setCurrentTab('Cloud Incident Response')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-center text-lg font-bold tracking-tight sm:py-4">Cloud Incident Response</button>
          <button onClick={() => setCurrentTab('Logging Visibility Coverage')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold tracking-tight sm:py-4">Logging Visibility Coverage</button>
          <button onClick={() => setCurrentTab('Suspicious Email Analysis')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold tracking-tight sm:py-4" aria-current="page">Suspicious Email Analysis</button>
          <button onClick={() => setCurrentTab('Security Awareness Training')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold tracking-tight sm:py-4">Security Awareness Training</button>
        </nav>
      </div>
    </div>
    
    {/* Beginning of Cloud Incident Response Section */}
    {currentTab === 'Cloud Incident Response' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Cloud Incident Response</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - In the face of a cyber incident in your cloud environment, your first action should be to reach out to us immediately. Our expert team is available round the clock to offer immediate support and guidance.</span>
                </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-56 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Incident Identification</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Our expert team jumps into action, conducting a rapid, yet thorough analysis of your system to understand the depth and extent of the ransomware infection.</span>
                </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Strategy Development</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Once we've identified the problem, we work swiftly to contain it, preventing it from spreading further in your cloud infrastructure. This step involves isolation of affected systems, shutdown if necessary, and removal of the threat.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Implementation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - After the threat has been eradicated, our focus shifts to recovery. We help restore your systems and data from backups, ensuring minimal disruption to your business operations. We'll also work on patching vulnerabilities and strengthening security measures.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Prevention Plan</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - The final step is a thorough analysis of the incident. We study the attack, understand how it succeeded, and use this knowledge to fortify your cloud environment against future incidents. This also involves providing recommendations and implementing prevention measures.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
  )}

  {/* Beginning of Logging Visibility Coverage Section */}
    {currentTab === 'Logging Visibility Coverage' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-24" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Logging Visibility Coverage</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - As soon as you're aware of any logging visibility issues, reach out to us immediately. Our dedicated team is available 24/7 to guide you and initiate the process.</span>
                </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-yellow-400 sm:h-16 sm:w-16 ">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Assessment</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - We start by assessing your current logging and visibility landscape. This involves understanding what kind of data is being logged, how it's being tracked, and if there are any gaps that might allow an adversary to go undetected.</span>
                </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-yellow-400 sm:h-16 sm:w-16">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Forensic Data Collection</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Post-assessment, we map out your logging structure and pinpoint the areas of improvement. This includes deciding what additional data needs to be logged and how to enhance visibility to prevent any potential threats.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Implementation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - With the plan in place, we proceed to implement changes. This could involve deploying new tools, customizing your existing systems for better logging, and fine-tuning your detection mechanisms to ensure nothing slips through.</span>
                </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Monitoring & Periodic Reviews</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - Once the improvements are made, it doesn't end there. We set up monitoring systems to ensure that your logging and visibility remain at their best. Plus, we conduct periodic reviews to stay ahead of evolving threats.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
    )}
    
    {/* Beginning of Suspicious Email Analysis Section */}
    {currentTab === 'Suspicious Email Analysis' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black ">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Suspicious Email Analysis</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - If you spot a suspicious email, your first step should always be to contact us. Our team is always on standby, ready to address your concerns and start the investigation process.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-24 h-80 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-40" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Analysis & Identification</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Once you've notified us, our expert team begins the process of analyzing the suspicious email. This involves checking the sender's details, analyzing the email content, attachments, and links. We employ advanced techniques to identify potential threats without activating them.</span>
              </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Threat Evaluation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - If the email is found to carry a threat, we categorize it based on its type and potential impact. This helps determine the necessary course of action, whether it's phishing, malware, or another type of cyber threat.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Remediation & Prevention</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Once we've fully understood the threat, we swiftly take action to isolate and eliminate it. We also implement measures to prevent similar threats in the future, which may include strengthening email filters and conducting user awareness training.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Report & Follow-up</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - After the issue is resolved, we provide a detailed report outlining the nature of the threat, how it was handled, and recommendations for future prevention. We also remain available for any follow-up questions or concerns.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
  )}
  
  {/* Beginning of Security Awareness Training Section */}
    {currentTab === 'Security Awareness Training' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black ">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-60 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Security Awareness Training</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - Your journey towards a cyber-resilient workplace starts by reaching out to us. We're ready to equip your team with the knowledge and skills needed to protect your digital assets.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Needs Assessment</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Based on the assessment, we develop a tailored Security Awareness Training program. This plan is designed to address your unique needs, focusing on the most relevant cyber threats to your business..</span>
              </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Customized Training Plan</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Based on the assessment, we develop a tailored Security Awareness Training program. This plan is designed to address your unique needs, focusing on the most relevant cyber threats to your business.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-16 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Training Implementation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Next, we execute the training program. This can include interactive workshops, online modules, real-world simulations, and regular testing to ensure understanding and retention..</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Resilience Building</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - Post-training, we don't just walk away. We continuously monitor and evaluate the effectiveness of the training program, adjusting as necessary to address new threats or gaps in knowledge.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
  )}
</div>
);
}

export default SOProcess;
