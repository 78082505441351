import React from "react";
import { BrowserRouter, Route, Routes as Switch } from "react-router-dom";
import CloudWatchRum from "../Sections/CloudWatchRum";
import GoogleTag from "../Sections/GoogleTag";
import Homepage from "../Pages/Homepage";
import Error from "../Pages/Error";
import About from "../Pages/About";
import News from "../Pages/News";
import Business from "../Pages/Business";
import LawFirms from "../Pages/LawFirms";
import InsuranceCarriers from "../Pages/InsuranceCarriers";
import IncidentResponse from "../Pages/IncidentResponse";
import SecurityOperations from "../Pages/SecurityOperations";
import AdvisoryServices from "../Pages/AdvisoryServices";
import ContactUs from "../Pages/ContactUs";
import Hotline from "../Pages/Hotline";


function Routes() {
  return (
    <div>
      <CloudWatchRum />
      <GoogleTag />
      <BrowserRouter>
        <Switch>
          <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/news" element={<News />} />
          <Route path="/business" element={<Business />} />
          <Route path="/law-firms" element={<LawFirms />} />
          <Route path="/insurance-carriers" element={<InsuranceCarriers />} />
          <Route path="/incident-response" element={<IncidentResponse />} />
          <Route path="/security-operations" element={<SecurityOperations />} />
          <Route path="/advisory-services" element={<AdvisoryServices />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/urgent" element={<Hotline />} />
          <Route path="*" element={<Error />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Routes;