import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../Animations/security-operations.json';

function SOPageGraphic() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <div className="bg-black py-5 sm:py-5">
      <div className="relative isolate">
        <div className="mx-auto sm:max-w-max sm:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 sm:rounded-3xl sm:p-8 sm:mx-0 sm:max-w-none sm:flex-row sm:items-center sm:py-20 xl:gap-x-20 xl:px-20">
            <div ref={container} className="h-96 w-full flex-none rounded-2xl object-cover aspect-square sm:h-auto sm:max-w-sm"></div>
            <div className="w-full flex-auto">
              <h2 className="text-center sm:text-left text-4xl font-bold tracking-tight text-white sm:text-5xl">Security Operations</h2>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SOPageGraphic;