import React from "react";
import Ruger from "../Sections/Ruger";
import Solutions from "../Sections/Solutions";
import Footer from "../Sections/Footer";
import TopBarMenu from "../Sections/TopBarMenu";
import Testimonials from "../Sections/Testimonials";

function About() {
  return (
    <div>
    <TopBarMenu />
	  <Ruger />
    <Testimonials />
    <Solutions />
    <Footer />
    </div>
  );
}

export default About;