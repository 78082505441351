import React from 'react';
import ymkbusiness from '../images/ymk-business.webp'

const Magixx = () => {
    return (
        <>
            {/* Beginning of Businesses Header Section */}
            <div className="bg-black px-6 py-1 sm:py-10 sm:px-8">
                <div className="mx-auto max-w-4xl sm:max-w-4xl text-center">
                    <p className="text-lg font-semibold leading-7 text-white sm:text-xl">BUSINESSES</p>
                    <h2 className="mt-5 text-3xl font-bold tracking-tight text-yellow-400 sm:text-6xl sm:mt-5">Empowering Businesses with Top-notch Cybersecurity</h2>
                    <p className="mx-auto mt-6 text-base leading-7 text-white text-center sm:text-center">YMK-7'S expert incident response team is ready 24/7 to jump into action. We take care of threats, while you take a deep breath. It's not just about fixing the breach. It's about securing your future. Fear no more, sleep easy. Let us handle the threats.</p>
                </div>
            </div>
            {/* End of Businesses Header Section */}

            {/* Beginning of Image Header Section */}
            <div className="bg-black">
                <div className="mx-auto px-6 py-16 sm:px-8 sm:ps-10 sm:max-w-7xl sm:py-5">
                    <div className="flex items-center">
                        <img src={ymkbusiness} alt="App screenshot" width="2432" height="1442" className="-mt-10 sm:-mt-10 rounded-md sm:pb-5 pb-1 " />
                    </div>
                </div>
            </div>
            {/* End of Image Header Section */}

            {/* Beginning of Challenges & Solutions Sections */}
            <div className="isolate overflow-hidden bg-black">
                <div className="mx-auto sm:max-w-full pb-96 text-center sm:pt-6 sm:px-8">
                    <div className="mx-auto max-w-4xl ">
                        <p className="mt-0 text-3xl font-bold tracking-tight text-white sm:text-5xl"> Challenges & Solutions <br className="hidden sm:hidden" /></p>
                    </div>
                </div>
                <div className="flow-root bg-black pb-10 sm:pb-10">
                    <div className="-mt-96 sm:-mt-80">
                        <div className="mx-auto max-w-screen-2xl px-6 sm:px-8">
                            <div className="mx-auto grid max-w-md grid-cols-1 gap-5 sm:max-w-4xl sm:grid-cols-2">
                                {/* Box #1 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">Can Your Business Afford Downtime?</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Your business thrives on constant connectivity. In today's digital age, even a short offline stint can lead to massive losses in revenue and customer trust.</p>
                                    </div>
                                </div>

                                {/* Box #2 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">We’re On it 24/7.</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Our incident response team is ready round-the-clock to get your systems up and running as swiftly as possible.</p>
                                    </div>
                                </div>

                                {/* Box #3 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">What's the Cost of Lost Data?</h3>
                                        <p className="mt-6 text-base leading-7 text-white">A breach can result in your valuable data falling into the wrong hands. Think of customer info, financial records, and trade secrets.</p>
                                    </div>
                                </div>

                                {/* Box #4 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">We Limit Damage.</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Our rapid response minimizes data loss and helps safeguard your critical information.</p>
                                    </div>
                                </div>

                                {/* Box #3 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">Can You Navigate Legal Hurdles?</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Post-breach, you face legal obligations. Non-compliance can mean hefty fines.</p>
                                    </div>
                                </div>

                                {/* Box #4 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">We Know the Rules.</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Our team helps ensure you comply with regulatory requirements, avoiding potential penalties.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Challenges & Solutions Sections */}
        </>
    );
}

export default Magixx;
