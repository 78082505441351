import React from "react";
import Footer from "../Sections/Footer";
import AdvSerProcess from "../Sections/AdvSerProcess";
import Stats from "../Sections/Stats";
import ASPageGraphic from "../Sections/ASPageGraphic";
import TopBarMenu from "../Sections/TopBarMenu";
import ClickToAction from "../Sections/ClickToAction"
import Testimonials from "../Sections/Testimonials";



function AdvisoryServices() {
  return (
    <div>
    <TopBarMenu />
    <ASPageGraphic /> 
    <AdvSerProcess /> 
    <Testimonials />
    <ClickToAction />
    <Stats /> 
    <Footer />
    </div>
  );
}

export default AdvisoryServices;