import React, { useState } from 'react';

const faqs = [
  {
    question: 'I have a small business, do I really need incident response services?',
    answer: "Absolutely! Imagine a family-owned restaurant whose online reservation system was hacked, resulting in loss of reservations and reputation. Having incident response services would have mitigated the damage."
  },
  {
    question: 'We have an IT team, they can handle incident response right?',
    answer: ' Your IT team is like your family doctor, they are great for general health. But for a complex surgery, you need a specialist. Incident response services are the specialists in the cyber world.'
  },
  {
    question: 'How does the incident response process work?',
    answer: ' Think of it like handling a medical emergency. There is an initial triage (detection and analysis), treatment (containment, eradication), and recovery, followed by learning and prevention measures (post-incident activity).'
  },
  {
    question: 'How can we trust your incident response team?',
    answer: 'One of our clients, a medium-sized eCommerce business, trusted us to handle a severe phishing attack. We successfully contained and remediated the breach, saving them from significant financial and reputational harm.'
  },
  {
    question: 'What happens if we do not act promptly on a cyber breach?',
    answer: 'Every minute counts in a cyber breach. Delays can lead to further data loss, financial damage, reputational harm, and potential legal consequences.'
  },
  {
    question: 'What should we do right now if we suspect a cyber breach?',
    answer: 'Imagine you smell smoke in your house - you would immediately call the fire department, right? Similarly, if you suspect a breach, contact us immediately. Our incident response team can quickly get to work on containing and remedying the threat.'
  },
  // Add more FAQs here as necessary...
];

const FrequentlyAsked = () => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="bg-black
    ">
      <div className="mx-auto max-w-7xl px-6 py-10 sm:px-8 sm:py-14">
        <div className="mx-auto max-w-4xl divide-y divide-white">
          <h2 className="text-2xl text-center font-bold tracking-tight text-yellow-400 sm:text-center sm:text-4xl">Frequently Asked Questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-white">
            {faqs.map((faq, index) => (
              <div key={index} className="pt-6">
                <dt>
                  <button
                    type="button"
                    className="flex w-full items-start justify-between text-left text-white"
                    aria-controls={`faq-${index}`}
                    aria-expanded={openIndex === index}
                    onClick={() => setOpenIndex(openIndex === index ? null : index)}
                  >
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <svg
                        className={`${openIndex === index ? 'hidden' : ''} h-6 w-6`}
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                      </svg>
                      <svg
                        className={`${openIndex === index ? '' : 'hidden'} h-6 w-6`}
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                      </svg>
                    </span>
                  </button>
                </dt>
                <dd
                  className={`mt-2 pr-12 ${openIndex === index ? '' : 'hidden'}`}
                  id={`faq-${index}`}
                >
                  <p className="text-base leading-7 text-gray-300">{faq.answer}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAsked;
