import React from "react";
import Footer from "../Sections/Footer";
import OmahLay from "../Sections/OmahLay";
import Metrics from "../Sections/Metrics";
import ClickToAction from "../Sections/ClickToAction";
import TopBarMenu from "../Sections/TopBarMenu";



function LawFirms() {
  return (
    <div>
    <TopBarMenu />   
    <OmahLay />  
    <ClickToAction />   
    <Metrics /> 
    <Footer />
    </div>
  );
}

export default LawFirms;