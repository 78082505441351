import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Solutions = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const services = [
    {
      id: 'incident-response',
      title: 'Incident Response',
      description: 'Breach detected? Regain power, contain damage, and bounce back with our instant incident response services.',
      features: ['Ransomware Response', 'Business Email Compromise', 'Data Breach Response', 'Cyber Extortion'],
      path: '/incident-response',
    },
    {
      id: 'security-operations',
      title: 'Security Operations',
      description: '24/7 Vigilance. Expertise against threats. Your cyber shield with our security operations team.',
      features: ['Cloud Incident Response ', 'Logging Visibility Coverage', 'Suspicious Email Analysis', 'Security Awareness Training'],
      path: '/security-operations',
    },
    {
      id: 'advisory-services',
      title: 'Advisory Services',
      description: 'Our advisory team unravels cyber-threat trends by staying ahead and keeping you informed.',
      features: ['Cyber Strategy', 'Forensics Investigations', 'SaaS & API Security', 'Risk Assessments'],
      path: '/advisory-services',
    }
  ];

  return (
    <div className="isolate overflow-hidden bg-black">
      <div className="mx-auto pb-96 text-center sm:pt-1 sm:px-8">
        <div className="mx-auto">
          <p className="mt-10 text-xl font-bold tracking-tight text-white sm:text-4xl sm:mt-10">Your Journey Begins Here</p>
          <p className="mt-3 text-4xl font-bold tracking-tight text-yellow-400 sm:text-5xl sm:mt-3">Rebuilding Trust. Restoring Security.</p>
        </div>
        <div className="relative mt-10 sm:mt-10"></div>
      </div>
      <div className="flow-root bg-black pb-10 sm:pb-10">
        <div className="-mt-96">
          <div className="mx-auto px-6 sm:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 sm:max-w-screen-xl sm:grid-cols-3">
              {services.map((service) => (
                <div key={service.id} className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-4 ring-yellow-400 sm:p-10">
                  <div>
                    <h3 id={service.id} className="text-3xl text-center font-bold leading-7 text-white">{service.title}</h3>
                    <p className="mt-6 text-base leading-7 text-white">{service.description}</p>
                    <ul className="mt-5 space-y-4 text-sm leading-6 text-white">
                      {service.features.map((feature, index) => (
                        <li key={index} className="flex gap-x-3">
                          <svg className="h-6 w-5 flex-none text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                          </svg>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link
                    to={service.path}
                    aria-describedby={service.id}
                    className="mt-8 block rounded-md bg-white px-3.5 py-2 text-center text-sm font-semibold leading-6 align-text-bottom shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {service.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
