import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import mobilehero from '../images/mobile-hero-1.webp'
import desktophero from '../images/desktop-hero-1.webp'

const StickyTabs = ({ currentTab, onTabChange, background, textColor }) => {
  return (
    <div className={`fixed bottom-0 z-50 left-0 right-0 bg-black flex`}>
      <Link
        to="/contact-us"
        smooth={true}
        duration={500}
        className={`mx-1 text-left text-base font-bold text-black py-1 px-12 ring-4 ring-yellow-400 ${
          currentTab === 'Act Now - Contain The Breach' ? `bg-yellow-400 text-black` : `bg-yellow-400 text-yellow-400`
        }`}
        onClick={() => onTabChange('Act Now')}
      >
        Regain Control Now!
      </Link>
      <Link
        to="/about-us"
        smooth={true}
        duration={500}
        className={`mx-1 text-center font-bold text-base text-black py-2 px-6 ${
          currentTab === 'Learn More' ? `bg-yellow-400 text-black` : `bg-white text-black`
        }`}
        onClick={() => onTabChange('Learn More')}
      >
        Learn More 
      </Link>
    </div>
  );
};

const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isTabVisible, setIsTabVisible] = useState(isMobile); // Initially set to true for mobile devices
  const [currentTab, setCurrentTab] = useState('Act Now - Contain The Breach');

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    const handleScroll = () => {
      const yOffset = window.scrollY;
      const footerHeight = 100;
      const isAtFooter = yOffset + window.innerHeight >= document.body.offsetHeight - footerHeight;

      if (yOffset > 300 && !isAtFooter) {
        setIsTabVisible(true);
      } else {
        setIsTabVisible(isMobile && !isAtFooter);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <div className="relative isolate overflow-hidden bg-black">
      <div className="mx-auto sm:max-w-max px-10 pb-5 sm:pb-10 sm:flex sm:px-8 sm:py-5">
        <div className="mx-auto flex-shrink-0 sm:mx-0 sm:max-w-2xl sm:pt-1 lg:mt-10 md:mt-10">
          <div className="mt-5 sm:mt-1"></div>
          <h2 className="text-xl text-center font-bold tracking-tighter text-white sm:mt-1 sm:text-left lg:text-4xl md:mt-1 md:text-left md:text-4xl">Time is Ticking After A Cyber Attack.</h2>
          <h2 className="text-xl text-center font-bold tracking-tighter text-white sm:mt-1 sm:text-left lg:text-4xl md:mt-1 md:text-left md:text-4xl">Your Business. Your Reputation.</h2>
          <h2 className="text-xl text-center font-bold tracking-tighter text-white sm:mt-1 sm:text-left lg:text-4xl md:mt-1 md:text-left md:text-4xl">Hanging By A Thread!</h2>
          <h1 className="mt-5 text-3xl text-center leading-8 font-bold sm:text-left text-yellow-400 lg:mt-20 lg:text-7xl md:mt-16 md:text-7xl"> Urgent Cyber Breach Response: Act Now to Defend Your Business!</h1>
          {!isMobile && (
            <div className="mt-7 lg:mt-20 md:mt-20">
              <ScrollLink to="section1" smooth={true} duration={500}>
                <Link to="/contact-us" className="mx-auto rounded-md px-3 text-sm font-bold sm:px-16 sm:text-xl sm:text-left text-black hover:text-black bg-yellow-400 shadow-sm hover:bg-white ring-8 ring-yellow-400 hover:ring-white">
                Regain Control Now!
                </Link>
              </ScrollLink>
              <ScrollLink to="section2" smooth={true} duration={500}>
                <Link to="/about-us" className="ml-8 mx-auto rounded-md px-10 text-sm font-bold sm:text-xl sm:text-left text-black hover:text-black border border-white bg-white hover:bg-yellow-400 ring-8 ring-white hover:ring-yellow-400 hover:border-yellow-400">
                  Learn More
                </Link>
              </ScrollLink>
            </div>
          )}
        </div>

        {/* The Desktop image */}
        <div className="mx-auto mt-10 flex max-w-5xl ps-16">
          <div className="flex items-center justify-center h-full sm:max-w-2xl">
            <img src={desktophero} alt="App screenshot" width="2432" height="1442" className="max-w-screen-md hidden sm:block rounded-2xl" />
          </div>
        </div>
      </div>

    {/* The mobile image */}
      <div className="mx-auto px-6 sm:px-8 sm:ps-10 sm:max-w-7xl">
        <div className="flex items-center">
          <img
            src={mobilehero}
            alt="App screenshot mobile"
            width="2432"
            height="1442"
            className="-mt-5 rounded-md pb-1 sm:hidden"
          />
        </div>
      </div>

      {isTabVisible && isMobile && (
        <StickyTabs
          currentTab={currentTab}
          onTabChange={handleTabChange}
        />
      )}
    </div>
  );
};

export default HeroSection;