// CloudWatchRum.js

import { useEffect } from 'react';
import { AwsRum } from 'aws-rum-web';

function CloudWatchRum() {
  useEffect(() => {
    try {
      const config = {
        sessionSampleRate: 1,
        guestRoleArn: "arn:aws:iam::998329485419:role/RUM-Monitor-us-east-2-998329485419-4177065491961-Unauth",
        identityPoolId: "us-east-2:8886a8a5-eee5-4324-b6f8-800f1403683b",
        endpoint: "https://dataplane.rum.us-east-2.amazonaws.com",
        telemetries: ["performance","errors","http"],
        allowCookies: true,
        enableXRay: false
      };

      const APPLICATION_ID = '7922f7a6-a0f8-4f3b-8500-c1a0d053bab6';
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'us-east-2';

      const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }, []);

  return null; // This component doesn't render anything to the DOM
}

export default CloudWatchRum;
