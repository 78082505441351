import React, { useState, useEffect } from 'react';

function AdvSerProcess() {
  const [currentTab, setCurrentTab] = useState('Cyber Strategy');
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (['cyber-strategy', 'forensics-investigation', 'saas-api-security', 'risk-assessments'].includes(tab)) {
      setCurrentTab(tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
    }
  }, []);

  return (
  <div>    
    {/* Desktop Layout */}
      <div className="hidden sm:block">
       <div className="sm:mx-auto max-w-screen-xl border-8 border-yellow-400">
        <nav className="-mb-px flex divide-x-8 mx-auto max-w-2xl  divide-white space-x-4 sm:space-x-0 sm:max-w-7xl" aria-label="Tabs">
          <button onClick={() => setCurrentTab('Cyber Strategy')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4 py-4 px-1 text-center text-sm font-bold tracking-tight sm:text-xl">Cyber Strategy</button>
          <button onClick={() => setCurrentTab('Forensics Investigations')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4  py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl">Forensics Investigations</button>
          <button onClick={() => setCurrentTab('SaaS & API Security')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4  py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl" aria-current="page">SaaS & API Security</button>
          <button onClick={() => setCurrentTab('Risk Assessments')} className="border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-1/4 py-4 px-1 text-sm text-center font-bold tracking-tight sm:text-xl">Risk Assessments</button>
        </nav>
        </div>
      </div>

    {/* Mobile Layout */}
    <div className="block sm:hidden">
      <div className="sm:mx-auto max-w-screen-xl border-8 border-yellow-400">
        <nav className="flex flex-wrap divide-y-4 mx-auto max-w-2xl divide-white sm:space-x-0 sm:max-w-7xl" aria-label="Tabs">
          <button onClick={() => setCurrentTab('Cyber Strategy')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-center text-lg font-bold tracking-tight sm:py-4">Cyber Strategy</button>
          <button onClick={() => setCurrentTab('Forensics Investigations')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold tracking-tight sm:py-4">Forensics Investigations</button>
          <button onClick={() => setCurrentTab('SaaS & API Security')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold tracking-tight sm:py-4" aria-current="page">SaaS & API Security</button>
          <button onClick={() => setCurrentTab('Risk Assessments')} className="flex-grow block sm:inline-block border-black text-white bg-black hover:text-black hover:bg-yellow-400 w-full sm:w-auto py-4 px-1 text-lg text-center font-bold tracking-tight sm:py-4">Risk Assessments</button>
        </nav>
      </div>
    </div>
    
    {/* Beginning of Cyber Strategy Section */}
    {currentTab === 'Cyber Strategy' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            < div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Cyber Strategy</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - Kickstart your path to robust cybersecurity by reaching out to us. We're prepared to collaborate with you, creating tailored plans to bolster your defenses and reduce cyber risk.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" > Discovery and Assessment:</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - In this phase, we work closely with you to understand your business's unique context. This includes your industry, company size, digital footprint, existing cybersecurity measures, and potential threats you might face.</span>
              </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Strategy Development</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Based on our comprehensive assessment, we design a bespoke Cyber Strategy. This strategy is intended to strengthen your defenses, focusing on the threat vectors most relevant to your business and industry.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Implementation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Once the strategy is finalized, we guide you through its implementation. This can involve enhancing infrastructure, integrating new security solutions, training staff, or adjusting existing processes and protocols..</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Ongoing Support and Optimization</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - Our partnership doesn't end with strategy implementation. We continue to offer support, monitor the effectiveness of the strategy, and make adjustments as necessary in response to evolving threats or business changes.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
  )}

  {/* Beginning of Forensics Investigations Section */}
    {currentTab === 'Forensics Investigations' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Forensics Investigations</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - Initiate the first step towards uncovering the truth behind cyber incidents. Reach out to us and our team of expert forensic investigators will be ready to delve into the digital scene.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-yellow-400 sm:h-16 sm:w-16 ">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Case Assessment and Scoping</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - After your initial contact, we evaluate the situation to understand the nature and scale of the incident. This helps us determine the best tools and approaches to use in the investigation.</span>
              </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-yellow-400 sm:h-16 sm:w-16">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Forensic Data Collection</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 3 - We employ cutting-edge technology to gather all available digital evidence. This could include anything from server logs to hard drives. Our focus is on preserving data integrity to ensure its legal admissibility.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Data Analysis</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Using proprietary threat intelligence, we painstakingly analyze the collected data. We retrace threat actor activities, identify any vulnerabilities exploited, and work to uncover the full story behind the incident.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Reporting and Mitigation Recommendations</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - Once our investigation is complete, we provide you with a comprehensive report detailing our findings. We also offer recommendations for mitigating the risk of future incidents and strategies for bolstering your cybersecurity measures.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
    )}
    
    {/* Beginning of SaaS & API Security Section */}
    {currentTab === 'SaaS & API Security' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black ">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >SaaS & API Security</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - Begin your journey to a more secure SaaS and API environment by reaching out to us. Our experts are ready to help protect your systems from ever-evolving cyber threats.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" > System Assessment</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Upon initial contact, we evaluate your current SaaS and API configurations. This assessment helps us understand your architecture and identify potential vulnerabilities that could be exploited by threat actors.</span>
              </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-28 sm:h-40" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Security Design and Implementation:</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Using cutting-edge technology and threat intelligence, we develop and implement a comprehensive security solution tailored to your specific needs. Our security design aims to guard against unauthorized access and data breaches.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-28 sm:h-40" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Continuous Monitoring and Threat Detection</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Post-implementation, we offer continuous monitoring services to detect and respond to any suspicious activities in real time. This ongoing vigilance helps minimize the impact of any potential security incident.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">

            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Periodic Reviews and Updates</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - The cybersecurity landscape is constantly changing, and so are we. We periodically review your SaaS and API security measures to ensure they stay up-to-date and effective against the latest threats.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </div>
  )}
  
  {/* Beginning of Risk Assessments Section */}
    {currentTab === 'Risk Assessments' && (
      <div className="px-24 py-12 mt-5 sm:py-40 sm:px-6 bg-black ">
      <div className="flex items-center justify-center">  
      <div className="sm:max-w-xl">
        <ol className="space-y-12 sm:space-y-24 -mt-9">
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-yellow-400 sm:left-14 sm:top-24 sm:h-28" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-9 items-center">
                <span className="relative z-10 flex -ml-1 h-10 w-10 sm:h-28 sm:w-28 items-center justify-center rounded-full bg-yellow-400">
                  <svg className="h-8 w-5 sm:h-16 sm:w-16 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                  </svg>
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col sm:-mt-5 sm:ml-10">
                <span className="text-2xl sm:text-3xl font-bold text-white" >Risk Assessments</span>
                <span className="text-base mt-3 text-white sm:text-base">Step 1 - Start fortifying your cyber defenses today. Reach out to us to initiate the risk assessment process. Our team is poised to assist you in improving your security posture.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Security Program Evaluation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 2 - Upon your initial contact, our first task is to conduct an extensive evaluation of your existing security programs. We aim to understand your current strategies, vulnerabilities, and the effectiveness of your countermeasures against cyber threats.</span>
              </span>
            </div>
            
          </li>
          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-60 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-32" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Risk Analysis</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 3 - Leveraging our extensive experience and proprietary techniques, we conduct a detailed risk analysis. This step identifies and quantifies potential risks, including those related to regulatory non-compliance.</span>
              </span>
            </div>
          </li>

          <li className="relative pb-5">
            <div className="absolute left-4 top-20 h-72 -ml-px mt-0.5 w-0.5 bg-white sm:left-14 sm:top-24 sm:h-44" aria-hidden="true"></div>
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Risk Management Strategy Implementation</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 4 - Based on our assessment, we work with you to design and implement a comprehensive risk management strategy. This includes preparing for and responding to regulatory inquiries and potential threats in a way that minimizes impact on your operations.</span>
              </span>
            </div>
          </li>
          <li className="relative pb-1">
            <div className="group relative flex items-start">
              <span className="flex h-9 sm:h-16 sm:ps-6 items-center">
                <span className="relative z-10 flex h-8 w-8 sm:h-16 sm:w-16 items-center justify-center rounded-full border-2 border-white bg-yellow-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-white"></span>
                </span>
              </span>
              <span className="ml-4 sm:ml-10 flex min-w-0 flex-col">
                <span className="text-lg sm:text-3xl font-semibold text-white" >Continuous Monitoring and Compliance Check</span>
                <span className="mt-3 sm:mt-3 text-base text-white sm:text-base">Step 5 - We don't just leave you after setting up the strategy. Our services include continuous monitoring and periodic compliance checks to ensure that your business remains resilient and compliant amidst the ever-evolving cyber threat landscape.</span>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
  )}
</div>
);
}

export default AdvSerProcess;
