import React from 'react';

const Emergency = () => {
  return (
    <div className="bg-black px-6 py-1 sm:py-16 sm:px-8 sm:pb-5">
      <div className="mx-auto sm:max-w-full text-center">
        <h2 className="text-5xl font-bold tracking-tight text-center text-yellow-400 sm:text-6xl">Immediate Assistance</h2>
        <div className="bg-black py-10 sm:py-20">
          <div className="mx-auto max-w-7xl px-6 sm:px-8">
            <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 sm:mx-0 sm:max-w-none">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-3">
                <div>
                  <p className="mt-1 sm:mt-4 text-white text-center text-3xl font-bold sm:text-5xl">Need immediate assistance with an incident?</p>
                  <p className="mt-4 text-white text-center text-lg sm:text-xl">Don't face it alone. Reach out to our compassionate and experienced cyber emergency contacts who are here to support and guide you through this challenging time.</p>
                </div>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:col-span-2 sm:gap-8">
                  <div className="rounded-2xl bg-black p-10 ring-4 ring-yellow-400">
                    <h3 className="text-base font-semibold leading-7 text-white">USA</h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-white">
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd><a className="font-semibold text-yellow-400" href="mailto:urgent911@ymkseven.com">urgent911@ymkseven.com</a></dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Phone number</dt>
                        <a href="tel:+18444317615">+1 (844) 431-7615</a>
                      </div>
                    </dl>
                  </div>
                  <div className="rounded-2xl bg-black p-10 ring-4 ring-yellow-400">
                    <h3 className="text-base font-semibold leading-7 text-white">UK</h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-white">
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd><a className="font-semibold text-yellow-400" href="mailto:urgent999@ymkseven.com">urgent999@ymkseven.com</a></dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Phone number</dt>
                        <dd>+44 7451 222000</dd>
                      </div>
                    </dl>
                  </div>
                  <div className="rounded-2xl bg-black p-10 ring-4 ring-yellow-400">
                    <h3 className="text-base font-semibold leading-7 text-white">General Inquiries</h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-white">
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd><a className="font-semibold text-yellow-400" href="mailto:cyber@ymkseven.com">cyber@ymkseven.com</a></dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Phone number</dt>
                        <a href="tel:+18444317615">+1 (844) 431-7615</a>
                      </div>
                    </dl>
                  </div>
                  <div className="rounded-2xl bg-black p-10 ring-4 ring-yellow-400">
                    <h3 className="text-base font-semibold leading-7 text-white">Press</h3>
                    <dl className="mt-3 space-y-1 text-sm leading-6 text-white">
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd><a className="font-semibold text-yellow-400" href="mailto:media@ymkseven.com">media@ymkseven.com</a></dd>
                      </div>
                      <div className="mt-1">
                        <dt className="sr-only">Phone number</dt>
                        <a href="tel:+18444317615">+1 (844) 431-7615</a>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Emergency;
