import React from "react";
import TopBarMenu from "../Sections/TopBarMenu";
import ClickToAction from "../Sections/ClickToAction";
import Footer from "../Sections/Footer";
import Solutions from "../Sections/Solutions";
import Burnaboy from "../Sections/Burnaboy.js";
import Testimonials from "../Sections/Testimonials.js";

function ContactUs() {
  return (
    <div>
   	<TopBarMenu /> 
    <Burnaboy />
    <Testimonials />
	  <ClickToAction />
    <Solutions />
    <Footer />
    </div>
  );
}

export default ContactUs;