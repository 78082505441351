import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import YMK7Logo from '../images/ymk-7-logo.svg'


const DesktopMenu = ({ items, expanded, handleExpand }) => (
  <div className="hidden sm:flex sm:gap-x-2 sm:mr-auto">
    {Object.keys(items).map((item, index) => (
      <div key={index} className="relative">
        <button
          type="button"
          className="flex items-center gap-x-0 text-md font-semibold leading-6 text-white"
          onClick={() => handleExpand(item)}
          aria-expanded={expanded === item}
        >
          {item}
          <svg
            className="h-5 w-5 flex-none text-yellow-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {expanded === item && (
          <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {items[item].map((subItem, subIndex) => (
                <Link
                  to={subItem.path}
                  className="block px-4 py-2 text-sm text-black hover:bg-yellow-400 hover:text-black"
                  role="menuitem"
                  key={subIndex}
                >
                  {subItem.title}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    ))}
    <Link to="/urgent" className="rounded-md bg-black px-2 text-md font-medium text-white shadow-sm hover:bg-white hover:text-black ring-4 ring-yellow-400">
      URGENT HOTLINE
    </Link>
  </div>
);

const MobileMenu = ({ items, isMobileMenuOpen, handleExpand, expanded, handleMobileMenuToggle }) => (
  <div className={`sm:hidden fixed top-0 h-full w-full bg-black z-20 transition-transform duration-200 ease-in-out transform ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
    <button
      type="button"
      className="p-2 -mr-2 text-white absolute top-0 left-0"
      onClick={handleMobileMenuToggle}
      aria-controls="mobile-menu"
      aria-expanded={isMobileMenuOpen}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    {Object.keys(items).map((item, index) => (
      <div key={index} className="mt-16">
        <button
          type="button"
          className="flex items-center gap-x-0 text-lg font-semibold text-white"
          onClick={() => handleExpand(item)}
          aria-expanded={expanded === item}
        >
          {item}
          <svg
            className="h-10 w-10 flex-none text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {expanded === item && (
          <div className="mt-2 w-full bg-white text-black">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {items[item].map((subItem, subIndex) => (
                <Link
                  to={subItem.path}
                  className="block px-4 py-2 text-sm text-black hover:bg-yellow-400 hover:text-black"
                  role="menuitem"
                  key={subIndex}
                >
                  {subItem.title}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    ))}
    <div className="mt-14">
      <Link to="/urgent" className="rounded-md bg-black px-2 text-md font-medium text-white shadow-sm hover:bg-white hover:text-black ring-4 ring-yellow-400 ">
      URGENT HOTLINE
    </Link>
  </div>
  </div>
);

const HamburgerMenu = ({ handleMobileMenuToggle }) => (
  <button
    type="button"
    className="sm:hidden p-2 -mr-2 text-white"
    onClick={handleMobileMenuToggle}
    aria-controls="mobile-menu"
    aria-expanded="false"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
    </svg>
  </button>
);

const TopBarMenu = () => {
  const [expanded, setExpanded] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const items = {
    "WHAT WE DO": [
    { 
        title: 'Incident Response', 
        path: '/incident-response',  
        description: ['Ransomware Response', 'Business Email Compromise', 'Data Breach Response', 'Cyber Extortion'],
        mainItemPath: '/incident-response'
    },
    { 
        title: 'Security Operations', 
        path: '/security-operations',
        description: ['Cloud Security Monitoring', 'Logging Visibility Coverage', 'Suspicious Email Analysis', 'Security Awareness Training'],
        mainItemPath: '/insurance-carriers' 
    },
    { 
        title: 'Advisory Services', 
        path: '/advisory-services',
        description: ['Cyber Strategy & Defense', 'Forensic Investigations', 'Expert Witness Services', 'Data Breach Analysis'],
        mainItemPath: '/news'  
    },
    ],
    "WHO WE SERVE": [
        { 
            title: 'Businesses',
            path: '/business', 
            description: []
        },
        { 
            title: 'Law Firms',
            path: '/law-firms', 
            description: [] 
        },
        { 
            title: 'Insurance Carriers', 
            path: '/insurance-carriers',
            description: [] 
        },
        ],
    "WHO WE ARE": [
        { 
            title: 'About Us',
            path: '/about-us',
            description: []
        },
        { 
            title: 'News & Events', 
            path: '/news',
            description: [] 
        },
        { 
            title: 'Contact Us',
            path: '/contact-us', 
            description: [] 
        },
        ],
  };

  const handleExpand = (item) => {
    if (item === expanded) {
      setExpanded(false);
    } else {
      setExpanded(item);
    }
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setExpanded(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative">
      <header className="relative isolate z-10 bg-black">
        <nav className="mx-auto flex max-w-screen-xl items-center justify-between p-6 sm:px-0" aria-label="Global">
          <div className="flex items-center sm:flex-auto">
          <img src={YMK7Logo} alt="Logo" className="h-12 w-12" />
            <Link to="/" className="-m-1.5 p-1.5 text-xl sm:text-4xl font-semibold text-white">
              YMK-7
            </Link>
          </div>

          <DesktopMenu items={items} expanded={expanded} handleExpand={handleExpand} />
          <HamburgerMenu handleMobileMenuToggle={handleMobileMenuToggle} />
          <MobileMenu items={items} isMobileMenuOpen={isMobileMenuOpen} handleExpand={handleExpand} expanded={expanded} handleMobileMenuToggle={handleMobileMenuToggle} />
        </nav>
      </header>
    </div>
  );
};

export default TopBarMenu;
