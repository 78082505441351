import React from 'react';
import ymklawfirms from '../images/ymk-law-firms.webp'

const OmahLay = () => {
    return (
        <>
            {/* Beginning of Businesses Header Section */}
            <div className="bg-black px-6 py-1 sm:py-10 sm:px-8">
                <div className="mx-auto max-w-4xl sm:max-w-4xl text-center">
                    <p className="text-lg font-semibold leading-7 text-white sm:text-xl">LAW FIRMS</p>
                    <h2 className="mt-5 text-4xl font-bold tracking-tight text-yellow-400 sm:text-5xl sm:mt-5">In a Cyber Breach. Legal Risks Multiply</h2>
                    <p className="mx-auto mt-6 text-base leading-7 text-white text-center sm:text-center">Every step in a cyber crisis can become a legal pitfall. Missteps are not just costly but can also lead to long-term damage. With our incident response expertise, you can ensure your client's actions are strategic, compliant, and limit future legal troubles. Partner with us and turn the tide of crisis into an opportunity for your client.</p>
                </div>
            </div>
            {/* End of Businesses Header Section */}

            {/* Beginning of Image Header Section */}
            <div className="bg-black ">
                <div className="mx-auto px-6 py-16 sm:px-8 sm:ps-10 sm:max-w-7xl sm:py-5">
                    <div className="flex items-center">
                        <img src={ymklawfirms} alt="App screenshot" width="2432" height="1442" className="-mt-10 sm:-mt-10 rounded-md sm:pb-5 pb-1" />
                    </div>
                </div>
            </div>
            {/* End of Image Header Section */}

            {/* Beginning of Challenges & Solutions Sections */}
            <div className="isolate overflow-hidden bg-black">
                <div className="mx-auto sm:max-w-full pb-96 text-center sm:pt-6 sm:px-8">
                    <div className="mx-auto max-w-4xl">
                        <p className="mt-0 text-3xl font-bold tracking-tight text-white sm:text-5xl"> Challenges & Solutions <br className="hidden sm:hidden" /></p>
                    </div>
                </div>
                <div className="flow-root bg-black pb-24 sm:pb-10">
                    <div className="-mt-96 sm:-mt-80">
                        <div className="mx-auto max-w-screen-2xl px-6 sm:px-8">
                            <div className="mx-auto grid max-w-md grid-cols-1 gap-5 sm:max-w-4xl sm:grid-cols-2">
                                {/* Box #1 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl  sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">Post-Breach, the Legal Fallout Looms!</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Your client's recovery is not just about rebooting systems. It's about navigating the legal minefield that follows a breach.</p>
                                    </div>
                                </div>

                                {/* Box #2 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">Let Us Help You Chart the Right Legal Path!</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Our team helps you comprehend the cyber terrain. With us, you build a winning legal strategy, limiting liability and shaping the best narrative for your client.</p>
                                    </div>
                                </div>

                                {/* Box #3 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">Your Client's Cyber Defense Record is Under Fire!</h3>
                                        <p className="mt-6 text-base leading-7 text-white">In the aftermath of a breach, every move your client made pre-incident is scrutinized. The question looms - Did they do enough?.</p>
                                    </div>
                                </div>

                                {/* Box #4 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">Our Forensics Expertise Backs You Up!</h3>
                                        <p className="mt-6 text-base leading-7 text-white">We bring our deep forensic knowledge to your corner. Unpacking the complexity of cyber defense actions, we provide the insight you need to defend your client's prior efforts.</p>
                                    </div>
                                </div>

                                {/* Box #3 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ">
                                    <div>
                                        <p className="text-sm leading-7 text-white">CHALLENGE</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-white">Questions Over Cyber Insurance Claims?</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Filing an insurance claim post-breach is a nuanced game. A wrong move, and your client may face claim denials.</p>
                                    </div>
                                </div>

                                {/* Box #4 */}
                                <div className="flex flex-col justify-between rounded-3xl bg-black p-8 shadow-xl ring-2 ring-yellow-400 sm:p-10">
                                    <div>
                                        <p className="text-sm leading-7 text-white">SOLUTION</p>
                                        <h3 id="tier-incident-response" className="mt-2 sm:mt-3 text-3xl text-left font-bold leading-7 text-yellow-400">Our Experience Makes Claim Processing a Breeze!"</h3>
                                        <p className="mt-6 text-base leading-7 text-white">Our experts understand the ins and outs of cyber insurance. Let us guide you, ensuring your client's claim meets every requirement.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Challenges & Solutions Sections */}
        </>
    );
}

export default OmahLay;
