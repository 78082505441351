import React from "react";
import Footer from "../Sections/Footer";
import SOProcess from "../Sections/SOProcess";
import SOPageGraphic from "../Sections/SOPageGraphic";
import TopBarMenu from "../Sections/TopBarMenu";
import ClickToAction from "../Sections/ClickToAction"
import Testimonials from "../Sections/Testimonials"
import Metrics from "../Sections/Metrics.js"


function SecurityOperations() {
  return (
    <div>
    <TopBarMenu /> 
    <SOPageGraphic />     
    <SOProcess /> 
    <Testimonials />
    <ClickToAction /> 
    <Metrics /> 
    <Footer />
    </div>
  );
}

export default SecurityOperations;