import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData1 from '../Animations/respond-4.json';
import animationData2 from '../Animations/deter-1.json';
import animationData3 from '../Animations/recover-1.json';

const Burnaboy = () => {
  const container1 = useRef(null);
  const container2 = useRef(null);
  const container3 = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container1.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData1
    });

    lottie.loadAnimation({
      container: container2.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData2
    });

    lottie.loadAnimation({
      container: container3.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData3
    });
  }, []);

  return (
    <div className="bg-black">
      <div className="mx-auto max-w-7xl py-10 sm:px-2 sm:py-20 2xl:py-20">
        <div className="mx-auto max-w-2xl px-4 sm:max-w-7xl">
          <div className="">
            <h2 className="text-4xl text-center font-bold tracking-tight text-yellow-400 sm:text-5xl sm:text-center sm:-mt-5 md:text-4xl">Protect Your Future. Secure Your Business.</h2>
            <p className="mt-8 text-base leading-7 text-white text-center sm:text-lg">Picture the chaos as cyber attackers infiltrate your business, targeting your lifeline, your data, and the trust of your valued clients. In the face of relentless danger, hesitation only invites disaster. Embrace the power to protect - take immediate action to shield your business from potential devastation.</p>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-3 sm:gap-x-24">
            <div className="flex flex-col items-center">
              <div className="">
                <div className="w-20 h-20 sm:h-24 sm:w-24" ref={container1} />
              </div>
              <div className="mt-4 sm:ml-0 sm:mt-0">
                <h3 className="text-3xl font-bold text-center  text-white">SECURE</h3>
                <p className="mt-2 text-base leading-7 text-white text-center sm:max-w-lg">Safeguarding your business from cyber threats is our priority. Rest assured, we're here to respond swiftly and effectively.</p>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col items-center">
                <div className="">
                  <div className="w-20 h-20 sm:h-24 sm:w-24" ref={container2} />
                </div>
                <div className="mt-4 sm:ml-0 sm:mt-0">
                  <h3 className="text-3xl font-bold text-center  text-white">FIGHT BACK </h3>
                  <p className="mt-2 text-base leading-7 text-white text-center sm:max-w-lg">With our expertise in breach response, ransomware investigations, and cyber extortion, we're on the front lines, fighting for your business's safety.</p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col items-center">
                <div className="">
                  <div className="w-20 h-20 sm:h-24 sm:w-24" ref={container3} />
                </div>
                <div className="mt-4 sm:ml-0 sm:mt-0">
                  <h3 className="text-3xl font-bold text-center text-white">RESTORE</h3>
                  <p className="mt-2 text-base leading-7 text-white text-center sm:max-w-lg">We'll work tirelessly to stop the breach in its tracks, restore your systems, and ensure your business is back on its feet in no time.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Burnaboy;
